body {
  background-color: #0d0d0d;
  font-family: "Gothic A1", sans-serif;
}

#full-name {
  color: hsl(178, 27%, 82%);
  text-align: center;
  font-size: 30px;
}

.gallery-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  justify-content: center;
  padding: 0em 1em;
}
.gallery-container > * {
  flex-basis: 35%;
}
.gallery-container .rc-darken {
  opacity: 0.5;
}

svg#logo {
  width: 200px;
  height: 200px;
  margin-top: 1em;
}

svg#logo path {
  stroke: hsl(178, 27%, 85%);
  stroke-width: 75px;
}
svg#logo:hover path {
  stroke: hsl(178, 27%, 65%);
}

.logo-type {
  text-align: center;
  margin-bottom: 2em;
}
a {
  text-decoration: none;
  font-size: 16px;

  /* margin: 20px; */
  color: hsl(178, 27%, 55%);
}
a:hover {
  text-decoration: none;
}
#bio-p {
  text-align: center;
}
.avatar {
  width: 180px;
  height: 180px;
  border-radius: 50%;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.form {
  color: #dbe7e4;
  text-align: center;
}

input,
textarea {
  background: transparent;
  width: 100%;
  font-family: "Gothic A1", sans-serif;
  font-size: 1.05em;
}

textarea {
  resize: none;
}

.form .btn {
  color: hsl(165, 20%, 88%);
  background: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  height: 2.1em;
}

.form .btn:hover {
  color: hsl(165, 20%, 98%);
}

.form .btn:active {
  color: hsl(165, 20%, 78%);
}

.inner-container-bio #contact-btn {
  color: hsl(165, 20%, 88%);
  background: transparent;
  border: none;
  outline: none;
  cursor: pointer;
}

#contact-container {
  text-align: center;
}

.inner-container-bio #contact-btn:hover {
  color: hsl(165, 20%, 98%);
}

.inner-container-bio #contact-btn:active {
  color: hsl(165, 20%, 78%);
}

#back-btn-container {
  margin-top: 3%;
}

#back-btn-container #back-btn {
  color: hsl(165, 20%, 88%);
  background: transparent;
  border: none;
  outline: none;
  cursor: pointer;
}

#back-btn-container #back-btn:hover {
  color: hsl(165, 20%, 98%);
}

#back-btn-container #back-btn:active {
  color: hsl(165, 20%, 78%);
}

.input-container {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.input-container.ic-big {
  align-items: flex-start;
  padding-top: 0.5em;
}

.input-container .input-prefix-icon {
  width: 16px;
  margin: 0.5em 1em;
}

.input-container.ic-big .input-prefix-icon {
  margin-top: 0;
}

.input-container .input-inner-container {
  display: block;
  flex-grow: 1;
}

.input-container input,
.input-container input:focus,
.input-container textarea,
.input-container textarea:focus {
  color: #dbe7e4;
  border: none;
  outline: none;
}

.footer {
  text-align: center;
  margin-top: 5%;
}
.footer-colors {
  color: hsl(165, 20%, 88%);
}
.footer-colors:hover {
  color: hsl(165, 20%, 98%);
}
.footer-colors:active {
  color: hsl(165, 20%, 78%);
}

.footer a {
  display: inline-block;
  margin: 20px;
}
.inner-container-contact-form {
  padding: 1em;
  border-radius: 0.75em;
  background-color: #141414;
  -webkit-box-shadow: 0px 10px 14px -3px rgba(0, 0, 0, 0.45);
  box-shadow: 0px 10px 14px -3px rgba(0, 0, 0, 0.45);
}

.inner-container-bio {
  padding: 2em;
  border-radius: 0.75em;
  /* margin-top: 25vh; */
  background-color: #141414;
  -webkit-box-shadow: 0px 10px 14px -3px rgba(0, 0, 0, 0.45);
  box-shadow: 0px 10px 14px -3px rgba(0, 0, 0, 0.45);
}

.repo-link-title {
  font-size: 18px;
}

.logo-pages {
  display: flex;
  justify-content: center;
}
.logo-pages svg#logo {
  height: 100px;
  width: 100px;
  margin-bottom: 2em;
}
@media only screen and (max-width: 872px) {
  .inner-repo-container {
    width: 270px;
    height: 270px;
  }
  .intro {
    font-size: 20px;
  }

  .navbar {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .footer a {
    padding: 0em;
  }
}

.navbar {
  padding: 0em;
}
.inner-repo-container {
  /* width: 200px; */
  height: 200px;
  padding: 1em;
  margin: 0.5em 0.75em;
  border-radius: 0.75em;
  background-color: #141414;
  -webkit-box-shadow: 0px 10px 14px -3px rgba(0, 0, 0, 0.45);
  box-shadow: 0px 10px 14px -3px rgba(0, 0, 0, 0.45);
}
#forward-btn,
#backward-btn {
  color: hsl(165, 20%, 88%);
  background: transparent;
  border: none;
  outline: none;
  cursor: pointer;
}
#btn-container {
  text-align: center;
}
.not-found-container {
  width: 50%;
  text-align: center;
  padding: 1em;
  margin: 0 auto;
  margin-top: 30vh;
  border-radius: 0.75em;
  background-color: #141414;
  -webkit-box-shadow: 0px 10px 14px -3px rgba(0, 0, 0, 0.45);
  box-shadow: 0px 10px 14px -3px rgba(0, 0, 0, 0.45);
}
.back-home-btn {
  color: hsl(165, 20%, 88%);
  background: transparent;
  border: none;
  outline: none;
  cursor: pointer;
}
.not-found-container .back-home-btn:hover {
  color: hsl(165, 20%, 98%);
}

.not-found-container .back-home-btn:active {
  color: hsl(165, 20%, 78%);
}
.not-found-container {
  color: hsl(165, 20%, 98%);
  font-size: 20px;
}

.inner-repo-container .fa-external-link-alt {
  display: contents;
}
.inner-repo-container .logo-type {
  margin-bottom: 10px;
}
.logo-type:visited {
  color: hsl(165, 20%, 88%);
  text-decoration: none;
}
.logo-type:hover {
  color: hsl(165, 20%, 98%);
  text-decoration: none;
}

.current-project {
  margin: 1em;
}
.navbar a {
  padding: 0em;
  margin-top: 15px;
  margin-bottom: 15px;
  text-decoration: none;
}

.logo-type-projects {
  margin-bottom: 0.5em;
}
.nav-item {
  display: flex;
  color: white;
}

.nav-item:hover {
  color: hsl(178, 27%, 80%);
}

.intro {
  text-align: right;
  font-size: 44px;
}
.navbar-home {
  display: flex;
  flex-direction: column;
}

.lg-icons {
  padding: 1em;
}
